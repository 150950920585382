import React from 'react';
import PortfolioC from '../components/Portfolio';


const Portfolio = () => {
  
  return (
        <PortfolioC/>
  );
}


export default Portfolio;