import React from 'react';
import SpaceShooter from '../components/SpaceShooter';


const RecentProject = () => {
  
  return (
        <SpaceShooter/>
  );
}


export default RecentProject;