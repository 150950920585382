import React from 'react';
import MindWaveC from '../components/MindWave';


const MindWave = () => {
  
  return (
        <MindWaveC/>
  );
}


export default MindWave;